import React from 'react';
import Typewriter from '../components/Typewriter';
import '../styles/portfolio.css';

const Portfolio = () => {
    return (
        <div className="portfolio-container">
            <Typewriter text="Our Portfolio" />
            <p>Check out our successful projects and see the impact of our work.</p>

            <div className="portfolio-item">
                <h2>Project 1: AWS RDS & Tableau Integration</h2>
                <p>We leveraged AWS RDS and Tableau to automate data extraction and create a seamless pipeline for data visualization. Using Python and Linux, we streamlined the data flow, ensuring accurate and real-time insights for strategic decision-making.</p>
                <ul>
                    <li><strong>Problem:</strong> Manual data extraction was time-consuming and prone to errors.</li>
                    <li><strong>Solution:</strong> Architected the solution using AWS services, including Digital Ocean for hosting a Linux server running Python scripts for data extraction. Integrated with AWS RDS for a robust database solution, and visualized data using Tableau. Built custom visuals and dashboards for purchasing, operations, HR, and executive teams, unifying their data sources. Implemented KPIs to track performance across multiple domains, providing a comprehensive view of the business.</li>
                    <li><strong>Results:</strong> Improved data accuracy, reduced processing time by 70%, and enhanced decision-making with real-time insights.</li>
                </ul>
            </div>

            <div className="portfolio-item">
                <h2>Project 2: Opening New Dispensaries</h2>
                <p>Successfully opened multiple dispensaries, implementing scalable IT infrastructure and ensuring smooth operations. From planning to execution, we delivered comprehensive IT solutions tailored to the unique needs of each location.</p>
                <ul>
                    <li><strong>Problem:</strong> Rapid expansion required robust, secure, and scalable IT infrastructure across multiple locations.</li>
                    <li><strong>Solution:</strong> Designed and implemented a comprehensive IT system including:
                        <ul>
                            <li>Fortinet security stack for advanced threat protection and network segmentation</li>
                            <li>Dedicated internet connections with failover capabilities for high availability</li>
                            <li>Secure VPN tunnels for inter-location communication</li>
                            <li>Centralized management system for efficient IT administration</li>
                            <li>POS integration and inventory management systems</li>
                            <li>Compliance-focused security measures to meet industry regulations</li>
                        </ul>
                    </li>
                    <li><strong>Results:</strong> Opened five new dispensaries within a year, achieving 500% operational growth. Ensured 99.99% uptime, secure transactions, and seamless communication across all locations.</li>
                </ul>
            </div>

            <div className="portfolio-item">
                <h2>Project 3: CRM Migration & Advanced Marketing Integration</h2>
                <p>Successfully migrated from HubSpot CRM to Salesforce Marketing Cloud, implementing advanced marketing automation and analytics capabilities. Leveraged AI-powered tools to enhance customer engagement and drive targeted campaigns in the cannabis industry.</p>
                <ul>
                    <li><strong>Problem:</strong> Existing CRM system lacked advanced marketing capabilities and AI-driven insights needed for growth. Additionally, SMS messaging for cannabis businesses was challenging due to restrictions from mainstream providers.</li>
                    <li><strong>Solution:</strong>
                        <ul>
                            <li>Migrated from HubSpot CRM to Salesforce Marketing Cloud</li>
                            <li>Implemented Einstein AI for predictive analytics and personalization</li>
                            <li>Designed and executed Customer Journeys for tailored marketing experiences</li>
                            <li>Integrated AlpineIQ for SMS messaging, overcoming industry-specific restrictions in the cannabis sector</li>
                            <li>Developed custom APIs and data connectors to ensure seamless data flow between systems</li>
                        </ul>
                    </li>
                    <li><strong>Results:</strong> Enhanced customer targeting accuracy by 60%, increased campaign effectiveness by 40%, and improved overall customer satisfaction scores by 25%. Successfully implemented compliant SMS messaging for cannabis industry marketing.</li>
                </ul>
            </div>

            <div className="portfolio-item">
                <h2>Project 4: Enterprise Network Redesign & IT Modernization</h2>
                <p>Completely overhauled the existing network infrastructure and IT systems for a multi-location business, implementing cutting-edge technologies and best practices to enhance performance, security, and manageability.</p>
                <ul>
                    <li><strong>Problem:</strong> Outdated network infrastructure and IT systems were causing frequent downtime, security vulnerabilities, and inefficient operations across multiple business locations.</li>
                    <li><strong>Solution:</strong> Designed and implemented a comprehensive network and IT modernization plan, including:
                        <ul>
                            <li>Deployed a Cisco Meraki cloud-managed network, providing centralized control and visibility across all locations</li>
                            <li>Implemented SD-WAN technology for optimized inter-site connectivity and improved application performance</li>
                            <li>Established a zero-trust security model with Cisco Identity Services Engine (ISE) for granular access control</li>
                            <li>Migrated on-premises servers to a hybrid cloud environment using AWS and Azure services</li>
                            <li>Implemented Microsoft Intune for unified endpoint management of all company devices</li>
                            <li>Deployed a comprehensive backup and disaster recovery solution using Veeam and offsite replication</li>
                            <li>Established a 24/7 Network Operations Center (NOC) for proactive monitoring and incident response</li>
                            <li>Developed and implemented IT governance policies and procedures aligned with ITIL best practices</li>
                        </ul>
                    </li>
                    <li><strong>Results:</strong>
                        <ul>
                            <li>Reduced network downtime by 99%, achieving 99.999% uptime across all locations</li>
                            <li>Improved network performance by 200%, enhancing employee productivity and customer experience</li>
                            <li>Reduced IT operational costs by 30% through automation and centralized management</li>
                            <li>Enhanced security posture, with zero major security incidents in the 18 months following implementation</li>
                            <li>Achieved 100% compliance with industry regulations and standards</li>
                            <li>Reduced mean time to resolution (MTTR) for IT incidents by 60%</li>
                            <li>Improved employee satisfaction with IT services by 85%, as measured by internal surveys</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Portfolio;
