// src/pages/BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { blogData } from './BlogData';
import '../styles/global.css';
import '../styles/blog.css';

const BlogPost = () => {
    const { slug } = useParams();
    const post = blogData.find(post => post.slug === slug);

    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <div className="blog-post-container">
            <h1>{post.title}</h1>
            <img src={post.image} alt={post.title} className="blog-post-image" />
            <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </div>
    );
};

export default BlogPost;
