import React from 'react';
import Typewriter from '../components/Typewriter';
import '../styles/global.css';

const Home = () => {
    const bookingUrl = 'https://outlook.office365.com/owa/calendar/book@godard.io/bookings/'; // Replace with your actual Microsoft Bookings URL

    const handleButtonClick = () => {
        window.location.href = bookingUrl;
    };

    return (
        <main>
            <Typewriter text="Welcome to Godard IT Consulting" />
            <p>Providing top-notch IT consulting, development, cybersecurity, and DevOps services.</p>
            <button className="quote-button" onClick={handleButtonClick}>Get a Free Consultation</button>
            <section>
                <h2>Our Expertise</h2>
                <p>We specialize in providing comprehensive IT solutions to help your business thrive. Our services include IT consulting, software development, cybersecurity, and DevOps.</p>
            </section>

        </main>
    );
};

export default Home;
