// src/pages/BlogData.js
export const blogData = [
    {
        title: "Product Picking Detection: Kicking Off a Computer Vision Project",
        slug: "product-picking-detection-kickoff",
        image: "https://tbtech.co/wp-content/uploads/2020/01/Canna-Feat-01-1380x548.png",
        summary: "Introducing the Product Picking Detection Program, aimed at enhancing compliance and efficiency in cannabis dispensaries using computer vision.",
        content: `
            <h1>Product Picking Detection: Kicking Off a Computer Vision Project</h1>
            <h2>Introduction</h2>
            <p>Welcome to the first installment of our Product Picking Detection Program blog series! As the Founder, I'm excited to share the start of our project aimed at revolutionizing compliance and efficiency in cannabis dispensaries using computer vision. This post will cover the project's goals, the technologies we're planning to use, and our progress to date.</p>
            <img src="https://tbtech.co/wp-content/uploads/2020/01/Canna-Feat-01-1380x548.png" alt="Product Picking Detection" class="content-image"/>
            <h2>The Vision Behind Product Picking Detection</h2>
            <p>The vision for our Product Picking Detection Program is to leverage advanced computer vision techniques to automatically identify and record instances of product picking in cannabis dispensaries. This system will help prevent potential audits by ensuring that any mispicked products are detected and resolved before they are recorded with the state, enhancing compliance and operational efficiency.</p>
            <h2>The Planned Technology Stack</h2>
            <p>Here's what we're planning to use for this project:</p>
            <ul>
                <li><strong>Microsoft Planner</strong>: For managing the project's workflow, setting deadlines, and staying on track.</li>
                <li><strong>OpenCV/PIL</strong>: Essential libraries for implementing computer vision algorithms and image processing.</li>
                <li><strong>Python</strong>: The primary programming language for developing our detection model and system integration.</li>
                <li><strong>TensorFlow or PyTorch</strong>: For building and training our deep learning models if needed.</li>
                <li><strong>Git</strong>: For version control and collaborative development.</li>
            </ul>
            <h2>The Journey So Far</h2>
            <p>Here's a snapshot of our progress to date:</p>
            <h3>Phase 1: Project Planning and Requirement Gathering</h3>
            <ul>
                <li><strong>Project Scope Definition:</strong> Completed
                    <ul>
                        <li>Outlined project objectives and deliverables.</li>
                        <li>Developed a comprehensive project timeline using Microsoft Planner.</li>
                        <li>Documented the project charter to serve as a reference point for all stakeholders.</li>
                    </ul>
                </li>
                <li><strong>Stakeholder Identification:</strong> Completed
                    <ul>
                        <li>Identified key stakeholders including dispensary managers, compliance officers, and state regulatory authorities.</li>
                    </ul>
                </li>
                <li><strong>Initial Requirements Gathering:</strong> In Progress
                    <ul>
                        <li>Currently identifying key products to be detected by the system.</li>
                        <li>Planning the process for collecting and labeling video footage for training.</li>
                    </ul>
                </li>
            </ul>
            <h2>What Lies Ahead</h2>
            <p>Next, we'll be focusing on completing the Requirements Gathering phase and moving into Data Collection. Here's a glimpse of what's next:</p>
            <h3>Phase 2: Data Collection and Model Development</h3>
            <ul>
                <li><strong>Data Collection:</strong>
                    <ul>
                        <li>Collect video footage from participating dispensaries.</li>
                        <li>Label the collected data for training purposes.</li>
                    </ul>
                </li>
                <li><strong>Model Development:</strong>
                    <ul>
                        <li>Develop initial computer vision algorithms using OpenCV/PIL.</li>
                        <li>Train and validate the model on the collected dataset.</li>
                    </ul>
                </li>
                <li><strong>Preliminary Testing:</strong>
                    <ul>
                        <li>Conduct initial tests to assess model accuracy and performance.</li>
                        <li>Identify areas for improvement and refinement.</li>
                    </ul>
                </li>
            </ul>
            <h2>Conclusion</h2>
            <p>The Product Picking Detection Program is off to a strong start. The progress we've made in project planning and initial requirement gathering has laid a solid foundation for the next phases. By leveraging cutting-edge computer vision technologies, we're on our way to developing a powerful detection system that will enhance compliance and efficiency in cannabis dispensaries.</p>
            <p>Stay tuned for future updates as we delve deeper into data collection, model development, and system integration. Your support and interest in this project are greatly appreciated, and we look forward to sharing more exciting progress soon.</p>
        `
    }
];
