import React from 'react';
import '../styles/terms.css';

const Terms = () => {
    return (
        <div className="terms-container">
            <div className="terms-section">
                <h1>Terms of Service</h1>
                <p>These Terms of Service ("Terms") govern your use of our services and website ("Services") provided by Godard IT Consulting ("Company," "we," "our," or "us"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.</p>

                <h2>User Responsibilities</h2>
                <p>As a user of our Services, you agree to use the Services in a manner consistent with all applicable laws and regulations. You are responsible for any activity that occurs under your account. You must not misuse our Services, including but not limited to engaging in illegal activities, distributing malware, or attempting to gain unauthorized access to our systems.</p>

                <h2>Limitation of Liability</h2>
                <p>To the fullest extent permitted by applicable law, Godard IT Consulting shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use our Services; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our Services; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Services by any third party; (e) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Services; and/or (f) the defamatory, offensive, or illegal conduct of any third party.</p>

                <h2>Governing Law</h2>
                <p>These Terms shall be governed by and construed in accordance with the laws of the State of Illinois, without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction of the state and federal courts located in Illinois to resolve any dispute or claim arising from these Terms or your use of the Services.</p>

                <h2>Changes to Terms</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Services.</p>

                <h2>Contact Information</h2>
                <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@godard.io">support@godard.io</a>.</p>
            </div>
        </div>
    );
};

export default Terms;
