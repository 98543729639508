import React from 'react';
import Typewriter from '../components/Typewriter';
import '../styles/about.css';

const About = () => {
    return (
        <div className="about-container">
            <Typewriter text='About Us' />
            <p>At Godard IT Consulting, we are dedicated to delivering innovative IT solutions that drive business success. With a wealth of experience in IT consulting, development, cybersecurity, and DevOps, we help our clients achieve their business goals through strategic planning and expert advice.</p>

            <h2>Professional Summary</h2>
            <p>Our founder, <a href="https://www.linkedin.com/in/shaquelbr/" target="_blank" rel="noopener noreferrer">Shaquel</a>, is an IT professional and military veteran recognized for orchestrating a quintuple expansion within a year at a leading cannabis dispensary company. This transformative phase blended IT construction, project management, and software development, underpinned by proficiency in Python scripting and Linux system administration.</p>
            <p>Shaquel spearheaded the creation of scalable infrastructures that facilitated rapid growth while maintaining high operational efficiency and driving technological success. His expertise in data analysis and visualization informed strategic growth, and his adeptness in cloud-based solutions ensured agility and resilience. Commitment to CI/CD practices accelerated project management processes, aligning with the dynamic pace of development.</p>
            <p>Shaquel is an AWS Certified Solutions Architect, IBM Certified AI Professional Engineer, and AWS Certified Cloud Practitioner, bringing a wealth of knowledge in cloud architecture, artificial intelligence, and cloud computing to our consulting services.</p>

            <h2>Key Areas of Expertise</h2>
            <ul>
                <li>Python Scripting & Linux System Administration</li>
                <li>Strategic IT Infrastructure Development & Expansion</li>
                <li>Data Analysis & Visualization for Strategic Growth</li>
                <li>Agile Cloud Solutions & CI/CD Implementation</li>
                <li>Leadership in IT Project Management & Expansion Initiatives</li>
                <li>Cross-Functional Team Leadership & Mentorship</li>
                <li>AWS Cloud Architecture & Solutions Design</li>
                <li>Artificial Intelligence & Machine Learning</li>
                <li>Cloud Computing & AWS Services</li>
            </ul>



            <h2>Our Mission</h2>
            <p>Our mission is to deliver the best IT solutions to help our clients succeed in their business ventures. We are committed to applying our expertise in IT infrastructure, data analysis, and cloud solutions to provide our clients with the agility and resilience they need to thrive in a dynamic business environment.</p>

            <h2>Contact Us</h2>
            <p>We are here to help you with your IT needs. Contact us at <a href="mailto:info@godard.io">info@godard.io</a> or call us at (708) 305-8011.</p>
        </div>
    );
};

export default About;
