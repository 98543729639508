// src/pages/Quote.js
import React, { useState } from 'react';
import '../styles/quote.css';

const Quote = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        services: '',
        message: ''
    });

    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(process.env.REACT_APP_SUBMIT_QUOTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSubmissionStatus('success');
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData);
                setSubmissionStatus('error');
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmissionStatus('error');
        }
    };

    return (
        <div className="quote-container">
            <h2>Request a Quote</h2>
            {submissionStatus === 'success' ? (
                <p className="confirmation-message">Your request has been submitted! We will get back to you shortly.</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            placeholder="Enter your name"
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            placeholder="Enter your email"
                        />
                    </label>
                    <label>
                        Phone:
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            placeholder="Enter your phone number"
                        />
                    </label>
                    <label>
                        Services:
                        <textarea
                            name="services"
                            value={formData.services}
                            onChange={handleChange}
                            required
                            placeholder="Describe the services you need (e.g., IT consulting, software development, cybersecurity, DevOps, etc.)"
                        />
                    </label>
                    <label>
                        Additional Message:
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Any additional information or requests"
                        />
                    </label>
                    <button type="submit">Submit</button>
                </form>
            )}
            {submissionStatus === 'error' && (
                <p className="error-message">Failed to submit your request. Please try again later.</p>
            )}
        </div>
    );
};

export default Quote;