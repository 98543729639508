import React from 'react';
import Typewriter from 'typewriter-effect';

const TypewriterComponent = ({ text }) => {
    return (
        <Typewriter
            options={{
                strings: [text],
                autoStart: true,
                loop: false,
                delay: 75,
                deleteSpeed: 50,
                cursor: '|',
            }}
        />
    );
};

export default TypewriterComponent;
