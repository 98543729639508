import React from 'react';
import '../styles/privacy.css';

const Privacy = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>We take your privacy seriously and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to ensure its security.</p>

            <h2>Information Collection</h2>
            <p>We collect various types of information in order to provide and improve our services:</p>
            <ul>
                <li><strong>Personal Data:</strong> This includes information such as your name, email address, phone number, and other contact details that you provide when you contact us or use our services.</li>
                <li><strong>Usage Data:</strong> We collect information about how you use our services, such as your IP address, browser type, and operating system. This helps us improve our services and ensure they are user-friendly.</li>
                <li><strong>Cookies:</strong> We use cookies and similar tracking technologies to track the activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</li>
            </ul>

            <h2>Use of Information</h2>
            <p>We use the collected information for various purposes, including:</p>
            <ul>
                <li>To provide and maintain our services</li>
                <li>To notify you about changes to our services</li>
                <li>To allow you to participate in interactive features of our services when you choose to do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our services</li>
                <li>To monitor the usage of our services</li>
                <li>To detect, prevent, and address technical issues</li>
            </ul>

            <h2>Google Analytics</h2>
            <p>We use Google Analytics to collect information about the use of our website. Google Analytics collects information such as how often users visit our website, what pages they visit when they do so, and what other sites they used prior to coming to our website. We use the information we get from Google Analytics to improve our website and services.</p>
            <p>Google Analytics collects only the IP address assigned to you on the date you visit our website, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit our website, the cookie cannot be used by anyone but Google. Google's ability to use and share information collected by Google Analytics about your visits to our website is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser.</p>

            <h2>Data Security</h2>
            <p>We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>

            <h2>Third-Party Disclosure</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>

            <h2>Your Rights</h2>
            <p>You have the right to access, correct, or delete your personal data. You also have the right to object to or restrict certain types of processing of your personal data. If you wish to exercise any of these rights, please contact us at the email provided below.</p>

            <h2>Contact Information</h2>
            <p>If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:privacy@godard.io">privacy@godard.io</a>.</p>
        </div>
    );
};

export default Privacy;
