import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TypewriterComponent from '../components/Typewriter_nav.js';

const Navbar = () => {
    const [click, setClick] = useState(false);
    const [typewriterKey, setTypewriterKey] = useState(0);

    const handleClick = () => {
        setClick(!click);
        setTypewriterKey(prevKey => prevKey + 1); // Force re-render to restart animation
    };

    return (
        <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px', backgroundColor: '#20232a' }}>
            <h1
                style={{ marginLeft: '20px', color: '#61dafb', cursor: 'pointer', fontFamily: 'monospace', fontSize: '1.2em' }}
                onClick={handleClick}
            >
                {click ? (
                    <TypewriterComponent
                        key={typewriterKey}
                        text="AI | Machine Learning | Data Science | Networking | Cybersecurity"
                    />
                ) : (
                    'Godard IT Consulting'
                )}
            </h1>
            <ul style={{ listStyle: 'none', display: 'flex', margin: 0, padding: 0 }}>
                <li style={{ margin: '0 15px' }}><Link to="/" style={{ color: '#61dafb', textDecoration: 'none' }}>Home</Link></li>
                <li style={{ margin: '0 15px' }}><Link to="/about" style={{ color: '#61dafb', textDecoration: 'none' }}>About</Link></li>
                <li style={{ margin: '0 15px' }}><Link to="/services" style={{ color: '#61dafb', textDecoration: 'none' }}>Services</Link></li>
                <li style={{ margin: '0 15px' }}><Link to="/portfolio" style={{ color: '#61dafb', textDecoration: 'none' }}>Portfolio</Link></li>
                <li style={{ margin: '0 15px' }}><Link to="/blog" style={{ color: '#61dafb', textDecoration: 'none' }}>Blog</Link></li>
                <li style={{ margin: '0 15px' }}><Link to="/contact" style={{ color: '#61dafb', textDecoration: 'none' }}>Contact</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
