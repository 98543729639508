// src/components/Typewriter.js
import React from 'react';
import Typing from 'react-typing-effect';

const Typewriter = ({ text }) => {
    return (
        <Typing
            text={[text]}
            speed={100}
            eraseSpeed={50}
            eraseDelay={5000}
            typingDelay={1000}
            cursorClassName="typing-cursor"
            displayTextRenderer={(text, i) => {
                return (
                    <h1>
                        {text}
                    </h1>
                );
            }}
        />
    );
};

export default Typewriter;
