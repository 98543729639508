// src/pages/Contact.js
import React, { useState } from 'react';
import Typewriter from '../components/Typewriter';
import '../styles/contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(process.env.REACT_APP_SUBMIT_CONTACT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSubmissionStatus('success');
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData);
                setSubmissionStatus('error');
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmissionStatus('error');
        }
    };

    return (
        <div>
            <Typewriter text="Contact Us" />
            {submissionStatus === 'success' ? (
                <p className="confirmation-message">Your message has been sent! We will get back to you shortly.</p>
            ) : (
                <form className="contact-form" onSubmit={handleSubmit}>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <label>Subject:</label>
                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <label>Message:</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <br />
                    <button type="submit">Send</button>
                </form>
            )}
            {submissionStatus === 'error' && (
                <p className="error-message">Failed to send your message. Please try again later.</p>
            )}
            <p>Our office is located at 5050 N Broadway Chicago, IL 60640</p>
            <p>Contact us at (708) 305-8011 or info@godard.io</p>
        </div>
    );
};

export default Contact;