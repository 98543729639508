import React from 'react';
import { Link } from 'react-router-dom';
import { blogData } from './BlogData';
import Typewriter from '../components/Typewriter';
import '../styles/blog.css';

const Blog = () => {
    return (
        <div className="blog-container">
            <Typewriter text="Blog" />
            <p>Read our latest industry insights and updates on IT consulting, development, cybersecurity, and DevOps.</p>
            <div className="blog-grid">
                {blogData.map((post) => (
                    <div key={post.slug} className="blog-item">
                        <Link to={`/blog/${post.slug}`}>
                            <img src={post.image} alt={post.title} className="blog-image" />
                            <h2>{post.title}</h2>
                            <p>{post.summary}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;
