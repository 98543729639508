// src/pages/Services.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typewriter from '../components/Typewriter';
import '../styles/services.css';

const Services = () => {
    const navigate = useNavigate();

    const handleQuoteButtonClick = () => {
        navigate('/quote');
    };

    return (
        <div className="services-container">
            <Typewriter text='Our Services' />
            <section className="service-section">
                <h2>IT Consulting</h2>
                <p>Our IT consulting services help you achieve your business goals through strategic planning and expert advice. We offer tailored solutions to optimize your IT infrastructure, enhance operational efficiency, and drive business growth. Our expertise includes strategic IT infrastructure development and expansion, data analysis and visualization for strategic growth, cloud solutions, CI/CD implementation, and leadership in IT project management and expansion initiatives.</p>
            </section>
            <section className="service-section">
                <h2>Development</h2>
                <p>We provide full-stack development services to create robust and scalable software solutions. Our team specializes in developing applications that are secure, user-friendly, and aligned with your business objectives. Our services include full-stack development using Java, Python, and React.js, database solutions with MongoDB and AWS RDS, custom software solutions for unique business needs, and integration with various APIs including Salesforce, Microsoft Graph, and other third-party services.</p>
            </section>
            <section className="service-section">
                <h2>Cybersecurity</h2>
                <p>Protect your business with our advanced cybersecurity services designed to secure your data and infrastructure. We offer comprehensive security solutions to safeguard against cyber threats and ensure regulatory compliance. Our services include advanced threat detection and response with Elastic and Kibana, implementation of robust security measures and protocols, regular security audits and vulnerability assessments, and incident response and disaster recovery planning.</p>
            </section>
            <section className="service-section">
                <h2>DevOps</h2>
                <p>Our DevOps services ensure smooth and efficient operations by integrating development and operations teams. We streamline workflows, automate processes, and enhance collaboration to accelerate product delivery and improve quality. Our services include CI/CD pipeline setup and management, automation of deployment and monitoring processes, integration of development and operations workflows, and training and support for DevOps best practices.</p>
            </section>
            <section className="service-section">
                <h2>IT Construction & Networking</h2>
                <p>We provide comprehensive IT construction and networking services to build and manage scalable and reliable networks. Our services ensure seamless connectivity and efficient network management for your business. Our expertise includes planning, building, and delivering manageable networks to IT administrators, design and implementation of robust networking solutions, integration of advanced networking technologies for improved performance, and ongoing network management and support services.</p>
            </section>
            <section className="service-section">
                <h2>AI Integration</h2>
                <p>Leverage the power of artificial intelligence to transform your business processes and decision-making. Our AI integration services help you implement cutting-edge AI solutions tailored to your specific needs. We offer expertise in machine learning model development, natural language processing, computer vision applications, AI-powered analytics, and seamless integration of AI technologies into your existing systems and workflows.</p>
            </section>
            <button className='quote-button' onClick={handleQuoteButtonClick}>Get a Quote</button>
        </div>
    );
};

export default Services;
